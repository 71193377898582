import { useCallback } from 'react';
import { useNavigate } from '@remix-run/react';
import { atom, useAtomValue } from 'jotai';
import { toast } from 'sonner';
import { uiContextAtomDesignBoardsActions } from '@rocco/ui/design-board/components/DesignBoardsActions';
import { DisplayMode } from '@rocco/types/enum/display-mode';
import { ReactComponent as ArrowRightIcon } from '@rocco/icons/svg/arrow/arrow-right.svg';
import type { PinItemType } from '@modules/design-board/type';
import type { Pin } from 'generated/graphql';

interface ItemToFollow {
    id: number;
    libraryItemId?: number;
}

/**
 * Atom to store the list of pins.
 */
const pinsAtom = atom<Pin[]>([]);

/**
 * Atom with local storage synchronization for pins.
 */
export const pinsAtomWithLocalStorage = atom(
    (get) => get(pinsAtom),
    (get, set, newStr) => {
        set(pinsAtom, newStr as Pin[]);
        localStorage.setItem('pins', JSON.stringify(newStr));
    }
);

/**
 * Custom hook to handle following items to a design board.
 *
 * @returns An object containing the handleFollow and isInDesignBoard functions.
 */
export const useHandleFollow = () => {
    const designBoardsActions = useAtomValue(uiContextAtomDesignBoardsActions);
    const pins = useAtomValue(pinsAtomWithLocalStorage);
    const navigate = useNavigate();

    /**
     * Checks if an item is already in the design board.
     *
     * @param itemId - The ID of the item to check.
     * @param itemType - The type of the item to check.
     * @returns True if the item is in the design board, false otherwise.
     */
    const isInDesignBoard = useCallback(
        (itemId: number, itemType: PinItemType) => {
            return pins.some((pin: Pin) => {
                if (pin.__typename !== itemType) {
                    return false;
                }
                if (pin.__typename === 'PhotoPin') {
                    return pin.LibraryImageID === itemId;
                } else if (pin.__typename === 'MemberUploadPin') {
                    return pin.TypeID === itemId;
                }
                return pin.PageTypeID === itemId;
            });
        },
        [pins]
    );

    /**
     * Handles the action of following an item to a design board.
     *
     * @param items - The item(s) to follow. `id` is the ID of the item and `libraryItemId` is the ID of the associated library item (product, project, article).
     * @param itemType - The type of the items to follow.
     */
    const handleFollow = useCallback(
        async (items: ItemToFollow | ItemToFollow[], itemType: PinItemType) => {
            if (!designBoardsActions) {
                return;
            }
            const itemsToFollow = (
                Array.isArray(items) ? items : [items]
            ).filter((item) => item.id !== 0);

            if (!itemsToFollow.length) {
                return;
            }

            // Filter out items that are already in the design board
            const filteredItemsToFollow = itemsToFollow.filter(
                ({ id, libraryItemId }) =>
                    !isInDesignBoard(libraryItemId ?? id, itemType)
            );

            // Check if all items were already in the design board
            if (filteredItemsToFollow.length === 0) {
                toast.error('This item is already saved to your board', {
                    action: {
                        label: 'Dismiss',
                        onClick: () => toast.dismiss(),
                    },
                });
                return;
            }

            filteredItemsToFollow.forEach(({ id, libraryItemId }) => {
                designBoardsActions.trackingConfig?.onBeforeFollow?.(
                    libraryItemId ?? id
                );
            });
            const board =
                await designBoardsActions.showSelectDesignBoardModal(true);

            if (!board) {
                return;
            }

            designBoardsActions.trackingConfig?.onAfterBoardSelect?.(board);

            const addedCount = await designBoardsActions.addItemsToBoard(
                board.id,
                itemType,
                filteredItemsToFollow.map(({ id }) => id)
            );
            if (addedCount > 0) {
                filteredItemsToFollow.forEach(({ id, libraryItemId }) => {
                    designBoardsActions.trackingConfig?.onAfterFollow?.(
                        libraryItemId ?? id
                    );
                });

                if (designBoardsActions.displayMode === DisplayMode.Desktop) {
                    toast.success('Saved to Design Board', {
                        action: {
                            label: 'Dismiss',
                            onClick: () => toast.dismiss(),
                        },
                        cancel: {
                            label: (
                                <span className="flex items-center justify-between gap-2">
                                    <span>View Design Board</span>
                                    <ArrowRightIcon className="size-4.5" />
                                </span>
                            ),
                            onClick: () => {
                                navigate(
                                    `/member/design-boards/${board.urlSegment}`
                                );
                            },
                        },
                    });
                } else {
                    toast.success('Saved to Design Board', {
                        action: {
                            label: 'View',
                            onClick: () => {
                                navigate(
                                    `/member/design-boards/${board.urlSegment}`
                                );
                            },
                        },
                    });
                }
            }
        },
        [designBoardsActions, navigate, isInDesignBoard]
    );

    const onClick = (e: React.SyntheticEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return {
        handleFollow,
        isInDesignBoard,
        onClick,
    };
};
